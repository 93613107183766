section {
    padding: 60px 80px;

}

header {
    padding: 60px 80px;

}

p {
    font-size: 30px;
    margin-top: 0;
}

h4 {
    font-size: 42px;
    text-align: left;
}

h5 {
    font-size: 30px;
    margin-bottom: 0;
}

h6 {
    font-size: 30px;
    text-align: right;
}

.bumper-bg {
    display: block;
    max-width: 1920px;
    background-color: #FCFDFF;
    background-repeat: no-repeat;
    background-position: top -100px right -150px;
    background-attachment: scroll, local;

}

.bumper-spacer {
    height: 150px;
}

.cta-button>button {
    font-size: 30px;
}

.bumper-text {
    font-size: 48px;
    font-weight: 300;
    margin-top: -50px;
}

.logo-text {
    font-size: 48px;
    font-size: 36;
    font-style: 'serif';
    padding: 0;
    margin: 0;
    font-family: "tuna";
    font-weight: 300;
}

.tagline {
    font-size: 26px;
    font-weight: '300';
    padding: 0;
    margin: 0;
    display: 'block';
    text-align: 'left';

}

/* .tiny-only {
    visibility: 'hidden';
} */

@media only screen and (max-width: 1350px) {
    p {
        font-size: 24px;

    }

    .bumper-text {
        font-size: 36px;
        margin-top: 40px;
    }

    .logo-text {
        font-size: 36px;
    }

    .tagline {
        font-size: 24px;
    }

    .bumper-bg {
        background-position: top 100px right -150px;
        background-size: 820px;
    }

    .bumper-spacer {
        height: 100px;
    }

    p {
        font-size: 24px;
    }

    section {
        padding: 10px 44px;
        padding-bottom: 80px;
    }

    header {
        padding: 44px 50px;
    }

}

@media only screen and (max-width: 890px) {
    .bumper-text {
        font-size: 24px;
        margin-top: 40px;
    }

    .bumper-bg {
        background-position: top 150px right -100px;
        background-size: 100vw;
    }

    p {
        font-size: 24px;
    }

    h4 {
        font-size: 32px;
        text-align: left;
    }

    h5 {
        font-size: 24px;
        margin-bottom: 0;
    }

    section {
        padding: 10px 24px;
        padding-bottom: 40px;
    }

    header {
        padding: 10px 24px;
    }

    .cta-button>button {
        font-size: 24px;
    }

    .cta-button {
        display: flex;
        /* justify-content: center; */
        align-items: end;


    }

    .logo-text {
        font-size: 24px;
    }

    .tagline {
        font-size: 18px;
    }

    .testimonials {
        display: none
    }
}

@media only screen and (max-width: 600px) {

    .bumper-text {
        font-size: 24px;
        margin-top: 40px;
    }

    .bumper-bg {
        background-position: top 250px left 10px;

        background-size: 100vw;
    }

    p {
        font-size: 24px;
    }

    h4 {
        font-size: 32px;
        text-align: left;
    }

    h5 {
        font-size: 24px;
        margin-bottom: 0;
    }

    section {
        padding: 10px 24px;
        padding-bottom: 40px;
    }

    header {
        padding: 10px 24px;
    }

    .cta-button>button {
        font-size: 24px;
    }

    .cta-button {
        display: flex;
        justify-content: center;
        align-items: end;

    }

    .bumper-spacer {
        flex: 1;
    }

    .logo-text {
        font-size: 24px;
    }

    .tagline {
        font-size: 18px;
    }

    .testimonials {
        display: none
    }
}