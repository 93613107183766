body {

  font-family: "brandon-grotesque", sans-serif;
  font-weight: 300;
  font-style: normal;
}

p {
  font-size: 24px;
  text-align: left;

}

h4 {
  text-align: right;
}